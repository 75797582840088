"use client";

import { Bars4Icon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import { FC, KeyboardEventHandler, useMemo, useState } from "react";
import { useMockedSession } from "~/hooks/useMockedSession";
import { cn } from "~/utils/cn";
import AccountTypeSelector from "./jobseeker/SignUp/AccountTypeSelector";
import { Button } from "./v2/Button";
import { Input } from "./v2/Input";
import { ProfilePicture } from "./v2/ProfilePicture";
import OpenedSearchBar from "./v2/jobs/OpenedSearchBar";
import ScalisLogo from "/public/images/SCALIS_LOGO_BLUE.svg";
import useSearchBar from "../hooks/useSearchBar";
import React from "react";
import { useGetAvatar } from "~/hooks/useGetAvatar";

interface Props {
  className?: string;
}

const Navbar: FC<Props> = ({ className }) => {
  const { data: session, status, user } = useMockedSession();
  const { data: avatar } = useGetAvatar(session?.user.id, session?.avatarType);
  const [navOnMobile, setNavOnMobile] = useState(false);
  const [subShow, setSubmenuShow] = useState(false);

  const isSignedIn = status === "authenticated" && !!session;
  const isCompanyRole = ["COMPANY", "COMPANY_EMPLOYEE"].includes(
    session?.role || "",
  );

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const asPath = `${pathname}?${searchParams?.toString()}`;

  const handleNavBar = () => setNavOnMobile(currentState => !currentState);

  const submenuShow = () => setSubmenuShow(currentState => !currentState);

  const [openSearch, setOpenSearch] = useState(false);
  const currentPath = useMemo(() => {
    let pathList = unauthenticatedPaths;
    if (isSignedIn && isCompanyRole)
      pathList = companyPaths(session.user!.name!);
    if (isSignedIn && !isCompanyRole)
      pathList = jobSeekerPaths(session.user!.name!);

    return pathList.find(({ href }) => asPath === href);
  }, [isSignedIn, isCompanyRole, session, router]);

  const handleSearch: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === "Enter") {
      e.preventDefault();

      const queryString = new URLSearchParams(e.currentTarget.value).toString();

      router.push(`/jobs?${queryString}`);
    }
  };

  return (
    <>
      <nav
        data-testid="navbar"
        className={cn(
          "fixed left-0 top-0 z-40 flex w-screen border-b border-solid border-white-1-95 bg-white px-2 md:px-10",
          className,
        )}
      >
        <div className="relative mx-auto flex w-full items-center justify-between px-0 py-3 md:justify-start">
          <div className="hidden items-center justify-between md:flex">
            <Link href="/">
              <div className="relative h-[20px] w-[100px] text-xl font-bold">
                <Image
                  src={ScalisLogo}
                  layout="fill"
                  alt="scalis-logo"
                  priority
                />
              </div>
            </Link>
          </div>

          {!isCompanyRole && isSignedIn ? (
            <div className="w-full md:hidden">
              <MobileJobSeekerNavbar
                image={session?.profile_image}
                fullName={session.user_name ?? ""}
                id={session?.user?.name}
                onFocus={() => setOpenSearch(true)}
                openSearch={openSearch}
              />
            </div>
          ) : (
            <div className="flex w-full items-center justify-between px-4 md:hidden">
              <Button
                variant="ghost"
                className="p-3 text-accent-1100"
                onClick={handleNavBar}
                data-testid="navbar-toggle-button"
              >
                <Bars4Icon className="h-6 w-6 text-accent-1100" />
              </Button>
              <div
                className={cn(
                  "text-center align-middle text-xl font-semibold text-accent-1100",
                  { "flex w-full justify-center": isSignedIn },
                )}
              >
                <div
                  className={cn(
                    "relative h-[20px] w-[100px] text-xl font-bold",
                    {
                      "self-center": isSignedIn,
                    },
                  )}
                >
                  <Image
                    src={ScalisLogo}
                    layout="fill"
                    alt="scalis-logo"
                    priority
                  />
                </div>
              </div>
              {!isSignedIn && <AccountTypeSelector action="join" />}
            </div>
          )}

          <div className="only-desktop-show search-nav flex justify-center">
            <div className="ml-6 xl:w-96">
              <div className="input-group relative flex w-full flex-wrap items-stretch rounded-xl bg-primary-40">
                <Input
                  type="search"
                  className="h-[40px] rounded-xl bg-primary-40 pl-8 text-[#31426A] placeholder:text-[#31426A]"
                  placeholder="Search"
                  onKeyDown={handleSearch}
                />
                <div className="absolute left-2.5 top-1/2 -translate-y-1/2 text-[#31426A]">
                  <i className="mi-search"></i>
                </div>
              </div>
            </div>
          </div>

          {!isSignedIn ? (
            <NavbarItemWrapper isOnMobile={false}>
              <div className="flex items-center gap-6">
                {unauthenticatedPaths.map(elem => (
                  <NavbarItem key={elem.label} {...elem} />
                ))}
                <div className="ml-1 flex gap-4">
                  <AccountTypeSelector action="login" />
                  <AccountTypeSelector action="signup" />
                </div>
              </div>
            </NavbarItemWrapper>
          ) : (
            <NavbarItemWrapper isOnMobile={false}>
              <div className="flex items-center gap-6">
                <NavbarItem href="/jobs" label="Jobs" />
                {isCompanyRole ? (
                  <>
                    {companyPaths(session.user!.name!).map(elem => (
                      <NavbarItem key={elem.href} {...elem} />
                    ))}
                  </>
                ) : (
                  <>
                    {jobSeekerPaths(session.user!.name!).map(elem => (
                      <NavbarItem key={elem.href} {...elem} />
                    ))}
                  </>
                )}
                <ProfilePicture
                  role="button"
                  aria-label="profile picture"
                  onClick={submenuShow}
                  className="hidden h-[2.5rem] w-[2.5rem] md:block"
                  fullName={user?.name ?? ""}
                  src={avatar}
                />
                <ul
                  id="mymenuDropdown"
                  className={`${
                    subShow ? "block " : "hidden"
                  } dropmenu-content absolute right-0 top-[100%] z-50 rounded-[0.25rem] border border-[rgba(0,0,0,.15)] bg-white px-[0.6rem] py-[0.8rem] text-[15px] text-[#555]`}
                >
                  <li className="px-[10px] py-[5px] capitalize text-black">
                    <Link
                      href={
                        isCompanyRole
                          ? "/company/account"
                          : "/jobseeker/settings"
                      }
                    >
                      <span className="hover:text-scalis-logo-color">
                        Account settings
                      </span>
                    </Link>
                  </li>

                  <li className="px-[10px] py-[5px] capitalize text-black">
                    <a
                      href="/api/auth/logout"
                      className="cursor-pointer hover:text-scalis-logo-color"
                    >
                      Logout
                    </a>
                  </li>
                </ul>
                <NavbarItem
                  label="Account settings"
                  href={
                    isCompanyRole ? "/company/account" : "/jobseeker/settings"
                  }
                  className="block md:hidden"
                />
                <NavbarItem
                  label="Logout"
                  href="/"
                  className="block md:hidden"
                />
              </div>
            </NavbarItemWrapper>
          )}

          {isSignedIn && isCompanyRole ? (
            <Link
              className="px-3 py-2 text-accent-1100 md:hidden"
              href={`/${isCompanyRole ? "company" : "jobseeker"}/messaging`}
            >
              <i className="mi-message text-2xl"></i>
            </Link>
          ) : (
            <div />
          )}
        </div>
      </nav>
      {openSearch && (
        <div className="fixed left-0 top-[65px] z-40 w-screen border-t border-solid border-white-1-95 bg-white px-4 py-3 transition-all duration-200">
          <OpenedSearchBar isNavbar handleClose={() => setOpenSearch(false)} />
        </div>
      )}
      <div className={cn("hidden", { flex: navOnMobile })}>
        <SideBar onClose={handleNavBar} />
      </div>
    </>
  );
};

interface MobileJobSeekerNavbarProps {
  fullName: string;
  id: string;
  image?: string;
  onFocus?: () => void;
  openSearch?: boolean;
}

const MobileJobSeekerNavbar: React.FC<MobileJobSeekerNavbarProps> = ({
  id,
  image,
  fullName,
  onFocus,
  openSearch,
}) => {
  const router = useRouter();
  const { search } = useSearchBar();

  const goToJobSeekerProfile = () => {
    router.push("/jobseeker/my-profile");
  };

  return (
    <div className="flex items-start justify-between gap-3 px-4">
      <div className="w-10 cursor-pointer">
        <ProfilePicture
          aria-label="profile picture mobile"
          role="button"
          fullName={fullName}
          src={image}
          onClick={goToJobSeekerProfile}
        />
      </div>
      {openSearch ? (
        <div className="mb-6 mt-3 flex w-full items-center justify-center">
          <div className="relative h-[20px] w-[100px] text-xl font-bold">
            <Image src={ScalisLogo} layout="fill" alt="scalis-logo" priority />
          </div>
        </div>
      ) : (
        <div className="input-group relative flex w-full flex-wrap items-stretch rounded-xl bg-primary-40">
          <Input
            data-testid="jobseeker-mobile-search-input"
            type="search"
            className="h-[40px] rounded-xl border-0 bg-primary-40 pl-8 text-[#31426A] placeholder:text-[#31426A]"
            placeholder="Search"
            onClick={onFocus}
            defaultValue={search ?? ""}
          />
          <div className="absolute left-2.5 top-1/2 -translate-y-1/2 text-[#31426A]">
            <i className="mi-search"></i>
          </div>
        </div>
      )}
      <Link className="py-2 text-accent-1100" href={`/jobseeker/messaging`}>
        <svg
          fill="none"
          viewBox="0 0 24 24"
          height="24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V17C22 18.1046 21.1046 19 20 19H15.4142L12.7071 21.7071C12.3166 22.0976 11.6834 22.0976 11.2929 21.7071L8.58579 19H4C2.89543 19 2 18.1046 2 17V6ZM20 6H4V17H9C9.26522 17 9.51957 17.1054 9.70711 17.2929L12 19.5858L14.2929 17.2929C14.4804 17.1054 14.7348 17 15 17H20V6ZM6 9.5C6 8.94772 6.44772 8.5 7 8.5H17C17.5523 8.5 18 8.94772 18 9.5C18 10.0523 17.5523 10.5 17 10.5H7C6.44772 10.5 6 10.0523 6 9.5ZM6 13.5C6 12.9477 6.44772 12.5 7 12.5H13C13.5523 12.5 14 12.9477 14 13.5C14 14.0523 13.5523 14.5 13 14.5H7C6.44772 14.5 6 14.0523 6 13.5Z"
            fill="#001855"
          ></path>
        </svg>
      </Link>
    </div>
  );
};

export default Navbar;

interface INavbarItemWrapper {
  children: React.ReactElement;
  isOnMobile: boolean;
}

const NavbarItemWrapper = ({ children, isOnMobile }: INavbarItemWrapper) => {
  return (
    <div
      className={cn(
        "absolute left-0 top-12 ml-px flex w-full flex-col items-center gap-2 overflow-hidden bg-white md:relative md:top-0 md:max-h-screen md:flex-row md:justify-end md:overflow-visible md:py-0",
        isOnMobile ? "mt-4 max-h-52 py-2" : "max-h-0 py-0",
      )}
      id="navbar-collapse"
    >
      {children}
    </div>
  );
};
interface INavbarItem {
  href: string;
  label: string;
  className?: string;
}

const NavbarItem = ({ href, label, className }: INavbarItem) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const asPath = `${pathname}?${searchParams?.toString()}`;

  return (
    <Link key={href} href={href} className={className}>
      <span
        className={cn(
          "rounded-[5px] bg-white px-2 py-1 text-sm text-[#5A5A5A] hover:bg-[#E7F2FE] hover:text-scalis-logo-color",
          {
            "bg-[#E7F2FE] font-bold text-scalis-logo-color":
              (href === "/jobs" && pathname === "/jobs") ||
              (href !== "/jobs" && asPath.includes(href)) ||
              (href === "/home" && asPath === "/"),
          },
        )}
      >
        {label}
      </span>
    </Link>
  );
};

const unauthenticatedPaths = [
  { label: "Home", href: "/home" },
  { label: "Jobs", href: "/jobs" },
];

const companyPaths = (id: string) => [
  { label: "Dashboard", href: "/company/home" },
  { label: "Messaging", href: `/company/messaging` },
];
const jobSeekerPaths = (id: string) => [
  { label: "Dashboard", href: "/jobseeker/dashboard" },
  { label: "Messaging", href: `/jobseeker/messaging` },
  { label: "Profile", href: "/jobseeker/my-profile" },
];

interface SideBarProps {
  onClose: () => void;
}

const SideBar: React.FC<SideBarProps> = ({ onClose }) => {
  return (
    <div data-testid="nav-sidebar">
      <div className="pointer-events-none fixed z-40 h-screen w-screen bg-dark-color-text-100 opacity-10"></div>
      <div className="fixed z-[999] h-screen w-[360px] bg-white duration-500 ease-in-out">
        <div
          onClick={onClose}
          className="cursor-pointer border-b border-white-95 px-5 py-5"
        >
          <XMarkIcon className="h-6 w-6 text-accent-1100" />
        </div>
        <div className="flex-col border-b border-white-95 px-6 py-5">
          <Link href="/">
            <div className="relative mb-8 mt-1 h-4 w-[76.8px] text-xl font-bold">
              <Image
                src={ScalisLogo}
                layout="fill"
                alt="scalis-logo"
                priority
              />
            </div>
          </Link>

          <Link href="/jobs">
            <div className="pb-[36px] text-xl font-semibold text-dark-color-text-100">
              <h1>Jobs</h1>
            </div>
          </Link>

          <div className="mb-1 flex gap-4">
            <Link href={"/login"}>
              <Button
                className="border-r-3 h-10 w-[136px]"
                variant={"secondary"}
              >
                <div className="text-base text-primary-1-90">Login</div>
              </Button>
            </Link>
            <Link href={"/signup"}>
              <Button className="border-r-3 h-10 w-[136px]" variant={"default"}>
                <div className="text-base">Sign up</div>
              </Button>
            </Link>
          </div>
        </div>

        <div className="flex flex-col gap-6 px-6 py-5">
          <Link href="/public/tos">
            <div>
              <h2 className="text-sm text-white-1-70">Terms of Service</h2>
            </div>
          </Link>

          <Link href="/public/pp">
            <div>
              <h2 className="text-sm text-white-1-70">Privacy Policy</h2>
            </div>
          </Link>

          <Link href="/public/dpa">
            <div>
              <h2 className="text-sm text-white-1-70">
                Data Processing Agreement (DPA)
              </h2>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
