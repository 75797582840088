import Link, { LinkProps } from "next/link";
import { FC, PropsWithChildren, useState } from "react";
import { Button } from "~/components/v2/Button";
import { cn } from "~/utils/cn";

interface Props {
  action: "login" | "signup" | "join";
}

const map: {
  [key in "login" | "signup" | "join"]: {
    label: string;
    variant: "secondary" | "default";
  };
} = {
  login: { label: "Login", variant: "secondary" },
  signup: { label: "Sign up", variant: "default" },
  join: { label: "Join", variant: "default" },
};

const AccountTypeSelector: FC<Props> = ({ action }) => {
  const [open, setOpen] = useState(false);

  const handleMouseOver = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <div
      className="relative"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <Link href={`/${action === 'join' ? 'signup' : action}`}>
        <Button className="h-10 border-r-3" variant={map[action].variant}>
            {action === "login" ? (
              <div className="text-base text-[#001298]">
                {map[action].label}
              </div>
            ) : (
              <div className="text-base">
                {map[action].label}
              </div>
            )}
        </Button>
      </Link>
      <div
        className={cn(
          "flex flex-col absolute right-0 overflow-hidden bg-white rounded-xl shadow-md w-32 text-[#595959] transition-all",
          open ? "max-h-20" : "max-h-0"
        )}
      >
        <Item href={`/${action === 'join' ? 'signup' : action}?job_seeker`}>Job seeker</Item>
        <Item href={`/${action === 'join' ? 'signup' : action}?employer`}>Employer</Item>
      </div>
    </div>
  );
};

const Item: FC<PropsWithChildren<LinkProps>> = ({ children, ...props }) => {
  return (
    <Link {...props} className="px-3 py-1 hover:bg-[#F2F2F2]">
      {children}
    </Link>
  );
};

export default AccountTypeSelector;
