import {
  BriefcaseIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { FormEventHandler } from "react";
import useSearchBar from "~/hooks/useSearchBar";
import { cn } from "~/utils/cn";
import { Button } from "../Button";
import LocationField from "../LocationField";
import { SelectField } from "../SelectField";
import { TextField } from "../TextField";

const OpenedSearchBar: React.FC<{
  isNavbar?: boolean;
  handleClose?: () => void;
}> = ({ isNavbar, handleClose }) => {
  const {
    search,
    employeeType,
    handleChangeEmployeeType,
    handleSearchChange,
    handleChangeLocation,
    location,
    onSearch,
  } = useSearchBar();

  const handleSearch: FormEventHandler = (e) => {
    e.preventDefault();
    onSearch();
    handleClose?.();
  };

  return (
    <form
      data-testid="opened-search-bar"
      onSubmit={handleSearch}
      className={cn(
        "bg-white transition-all duration-200",
        !isNavbar ? "w-screen px-5 py-4" : "w-full py-1",
      )}
    >
      <div
        className={cn(
          "mx-auto flex max-w-[60rem] flex-col gap-2 lg:flex-row lg:gap-4",
          {
            "gap-0": !open,
          },
        )}
      >
        <TextField
          className="rounded-full pl-9 hover:bg-primary-40 focus:border-[1.5px] focus:border-primary focus-visible:ring-0"
          containerClassName="flex-1"
          placeholder={"Job Title / Keyword Designer"}
          onChange={handleSearchChange}
          value={search}
          startAdornment={<MagnifyingGlassIcon className="h-5 w-5" />}
        />
        <div
          className={cn(
            "flex h-[8.75rem] flex-col gap-2 overflow-hidden transition-all lg:h-full lg:flex-row lg:gap-4 ",
            { "h-0 lg:h-full": !open },
          )}
        >
          <LocationField
            name="location"
            label=""
            value={location?.description}
            setValue={handleChangeLocation}
            className="min-w-[13.5rem] rounded-full pl-9 hover:bg-primary-40 focus:outline-none lg:max-w-[13.5rem]"
            placeholder="Location"
            startAdornment={<MapPinIcon className="h-5 w-5" />}
          />
          <SelectField
            options={[
              { label: "Full Time", value: "FULL_TIME" },
              { label: "Part Time", value: "PART_TIME" },
              { label: "Seasonal", value: "SEASONAL" },
              { label: "Contractor", value: "CONTRACTOR" },
              { label: "Internship", value: "INTERNSHIP" },
              { label: "Per-Diem", value: "PER_DIEM" },
              { label: "Temporary", value: "TEMPORARY" },
              { label: "Other", value: "OTHER" },
            ]}
            label=""
            multi={false}
            emptyTerm="type"
            name="employee_type"
            searchPlaceholder="Search employee type"
            value={employeeType || ""}
            setValue={handleChangeEmployeeType}
            className="min-w-full rounded-full pl-9 hover:bg-primary-40 focus:outline-none lg:max-w-[13.5rem]"
            placeholder="Employee type"
            startAdornment={<BriefcaseIcon className="h-5 w-5" />}
          />
          <Button className="hidden h-10 w-10 rounded-full px-2 lg:block">
            <MagnifyingGlassIcon className="h-6 w-6" />
          </Button>
          <div className="flex justify-between lg:hidden">
            <Button
              onClick={handleClose}
              className="h-10 w-[7.5rem]"
              variant="outline"
            >
              Cancel
            </Button>
            <Button type="submit" className="h-10 w-[7.5rem]">
              Search jobs
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default OpenedSearchBar;
