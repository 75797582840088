import Image from "next/image"

type ScalisLogoProps = {
  width?: number;
  height?: number;
}
export const ScalisLogo: React.FC<ScalisLogoProps> = ({width = 100, height = 100}) => (
  <Image
    src="/images/SCALIS_LOGO_BLUE.svg"
    alt="Scalis"
    width={width}
    height={height}
  />
)
